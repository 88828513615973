.home
{
    position:absolute;
    top:10px;
    z-index:1000;
    left: 1%
}

@media only screen and (min-width : 350px) and (max-width : 1000px) {
    .home {
        left: 7%
    }
}

@media only screen and (min-width : 1001px) {
    .home {
        left: 1%
    }
}