.contentMainMenu {
    height: 97vh;
    background-color: var(--form-bg-color) !important;
    max-height:97vh;
    overflow:hidden;
    font-size:15px !important
}

.border-red {
    border-style: solid !important;
    border-width: thin !important;
    border-color: red !important;
}

.text__rejected {
    background-image: url('../../assets/images/rejected.png');
    background-repeat: no-repeat;
    background-position: right
}

.bg__rejected {
    background-color: #c462e6
}

.text__overOneDay {
    color: #cc9900
}

.bg__overOneDay {
    background-color: #cc9900
}

.text__overTwoDay {
    color: #ac2475
}

.bg__overTwoDay {
    background-color: #ac2475
}

.text__overThreeDay {
    color: red;
}

.bg__overThreeDay {
    background-color: red;
}

.count{
    font-size:16px !important
}

.text__correction {
    background-image: url('../../assets/images/correction.png');
    background-repeat: no-repeat;
    background-position: right
    /* color : #0d6efd */
}

.text__rejcorrection {
    background-image: url('../../assets/images/rejectionandcorrection.png');
    background-repeat: no-repeat;
    background-position: right
}


.header__sticky--count {
    position: sticky;
    top: 0;
    background-color: var(--form-bg-color) !important;
}

.header__sticky--table {
    position: sticky;
    top: 6%;
}

.job__info {
    max-height: 88vh !important;
    overflow: auto !important;
}